import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8f4f6284&scoped=true"
import script from "./index.js?vue&type=script&lang=js&external"
export * from "./index.js?vue&type=script&lang=js&external"
import style0 from "./index copy.less?vue&type=style&index=0&id=8f4f6284&prod&lang=less&scoped=true&external"
import style1 from "./index.less?vue&type=style&index=1&id=8f4f6284&prod&lang=less&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f4f6284",
  null
  
)

export default component.exports