import { getRegin, getReginById } from "@/utils/api";

import Util from "@/utils/utils";
import {
  getStoreUser,
  delStoreUser,
  delStoreUser1,
  getStoreUser1,
} from "@/utils/store";

import {
  distributorWarehouse,
  getMatklList,
  getMatklListNew,
  getBscList,
  stepStartFlow,
} from "./api";

export const mixin = {
  data() {
    return {
      dialogTableVisible:false,
      filForm: [],
      shopInfoCheckDetailList: [],

      shopInfoModel: [
        {
          fullName:'',
          orgId: "", //办事处
          orgName: "", //办事处
          fgsName: "", //分公司
          fgsId: "", //分公司
          baseMatklId: "", //物料组
          baseMatklName: "", //物料组
          managerName: "", //仓库联系人姓名
          managerTel: "", //仓库联系人电话
          enterTime: "", //进驻时间
        },
      ],
      shopInfoModel1: [
        {
          orgId: "", //办事处
          orgName: "", //办事处
          fgsName: "", //分公司
          fgsId: "", //分公司
          baseMatklId: "", //物料组
          baseMatklName: "", //物料组
          managerName: "", //仓库联系人姓名
          managerTel: "", //仓库联系人电话
          enterTime: "", //进驻时间
        },
      ],

      listIndex: 0,
      baseMatklList: [],
      bscList: [],
      sessionData: [], //浏览器缓存数据
      adderFlag: false,
      beforedata: true, //存储浏览器数据之前
      affterdata: false, //存储浏览器数据之后
      afterCustomer: "", //所属商户
      Location: "", //所在地区
      shichangleve: "", //市场级别
      cityleve: "", //城市级别
      youbian: "", //邮编
      sessionfgsId: "", //缓存的分公司id
      wuliaozu: [], //缓存的物料组
      banshichu: [], //办事处
      visibledata: false, //弹窗
      addressHx: [], //地址
      disableFlag: false,
      showspan: "",
      tijiaoable: false, //提交禁用
      edit:false,
      isLoginModalShow:false,
      getIndex:0
    };
  },
  mounted() {
    if (window.name == "" && this.$route.query.category != "step") {
      delStoreUser();
      delStoreUser1();
      window.name = "isReload"; // 在首次进入页面时我们可以给window.name设置一个固定值
    } else if (
             window.name == "isReload" &&
             this.$route.query.category != "step"
           ) {
             delStoreUser();
             delStoreUser1();
           }
    // window.addEventListener("popstate", function(e) {
    //   if (getStoreUser1() == 3) {
    //     delStoreUser();
    //     delStoreUser1();
    //   }
    // });
    this.showspan = getStoreUser1();

    this.sessionData.push(getStoreUser());
    if (this.sessionData[0].showSelect) {
      this.affterdata = true;
      this.beforedata = false;
      this.afterCustomer = this.sessionData[0].distributorInfoVO.customerFullName;
      // this.Location=this.sessionData[0].distributorInfoVO.provinceId+this.sessionData[0].distributorInfoVO.cityId+this.sessionData[0].distributorInfoVO.countyId+this.sessionData[0].distributorInfoVO.townId
      this.shichangleve = this.sessionData[0].distributorInfoVO.marketLevelId;
      this.cityleve = this.sessionData[0].distributorInfoVO.districtType;
      this.youbian = this.sessionData[0].distributorInfoVO.postalCode;
      if(this.sessionData[0].wareHouseInfoDto){
        setTimeout(() => {
          this.formData.storeFullName=this.sessionData[0].wareHouseInfoDto.houseFullName
          this.formData.storeShortName=this.sessionData[0].wareHouseInfoDto.houseSearchTerm
          this.formData.mapAddress=this.sessionData[0].wareHouseInfoDto.houseAddress
          
          this.sessionData[0].wareHouseInfoDto.shopInfoDetailCkCheckDtoList.forEach((i) => {
            this.shopInfoCheckDetailList.push(
              {
                baseMatklName:i.fullName1,
                managerName:i.managerName,
                managerTel:i.managerTel,
                orgName:i.orgName,
                enterTime:i.enterTime,
                orgId:i.orgId,
                baseMatklId:i.materialGroupBranchCompany,
                fgsName: i.fgsName,//分公司
                fgsId: i.fgsId,//分公司
              }
            )
          });
          
          
        }, 1200);

      }
      this.wuliaozu.push(
        this.sessionData[0].distributorInfoVO.tradeRelationVOList
      );

      this.addressHx = [
        this.sessionData[0].distributorInfoVO.provinceId,
        this.sessionData[0].distributorInfoVO.cityId,
        this.sessionData[0].distributorInfoVO.countyId,
        this.sessionData[0].distributorInfoVO.townId,
      ];
    }
  },
  methods: {
    hideModal() {
      this.dialogTableVisible = false
      this.formData.mapAddress = this.$refs.bMap.form.address
    },
    onAddressChange(feed) {
      this.addressArr = feed;
      // debugger
    },
    formatDate(feed) {
      if (feed._d) {
        return Util.dateFmt(new Date(feed._d), "yyyy-MM-dd");
      } else {
        return feed;
      }
      // debugger
    },
    //编辑
    rewindToAdd(index) {
      this.adderFlag = true;
      this.edit=true
      this.shopInfoModel[0] = this.shopInfoCheckDetailList[index];
      this.shopInfoModel1[0] = this.shopInfoCheckDetailList[index];
      this.wuliaochange(this.shopInfoModel1[0].baseMatklName) 
    },
    //删除
    deleteShop(index) {
      this.isLoginModalShow=true
      this.getIndex=index
    },
    userLayout() {
      var index = this.getIndex;
      this.shopInfoCheckDetailList.splice(index, 1);
    },
    
    //取消
    showAdder() {
      this.adderFlag = !this.adderFlag;
    },
    //确定
    defineListData() {
      // debugger
      if (
        this.shopInfoCheckDetailList.find(
          (i) =>
            i.baseMatklName + i.orgId ==
            this.shopInfoModel[0].baseMatklName + this.shopInfoModel[0].orgId
        ) &&
        !this.edit
      ) {
        this.$message.warning("该所属办事处-物料组已维护！");
        return;
      }
      if (this.edit) {
        this.edit = false;
      }
    

      var phoneReg = /^1[0-9]{10}$/;
      if (!phoneReg.test(this.shopInfoModel[0].managerTel)) {
        this.$message.warning("请输入正确的仓库联系人电话");
        return;
      }
      if (!this.shopInfoModel[0].managerName) {
        this.$message.warning("请输入仓库联系人姓名");
        return;
      }
      if (!this.shopInfoModel[0].enterTime) {
        this.$message.warning("请输入进驻时间");
        return;
      }
      this.fillShopInfo();
      
      this.shopInfoCheckDetailList = [
        ...new Set([...this.shopInfoCheckDetailList, ...this.shopInfoModel]),
      ];

      // debugger

     (this.shopInfoModel = [
       {
         orgId: "", //办事处
         orgName: "", //办事处
         fgsName: "", //分公司
         fgsId: "", //分公司
         baseMatklId: "", //物料组
         baseMatklName: "", //物料组
         managerName: "", //仓库联系人姓名
         managerTel: "", //仓库联系人电话
         enterTime: "", //进驻时间
       },
     ]),
       this.showAdder();
      // let temp = new Date(this.shopInfoCheckDetailList[this.listIndex].enterTime)
      // debugger
    },
    //一步建仓点击确认
    addData() {  
      if(!this.shopInfoModel1[0].baseMatklName){
        this.$message.warning("请选择物料组");
        return;
      }
      if(!this.shopInfoModel1[0].orgId){
        this.$message.warning("请选择办事处");
        return;
      }
      var phoneReg = /^1[0-9]{10}$/;
      if (!phoneReg.test(this.shopInfoModel1[0].managerTel)) {
        this.$message.warning("请输入正确的仓库联系人电话");
        return false;
      } 
      if (!this.shopInfoModel1[0].managerName) {
        this.$message.warning("请输入仓库联系人姓名");
        return;
      }
      if (!this.shopInfoModel1[0].enterTime) {
        this.$message.warning("请输入进驻时间");
        return;
      }
      this.fillShopInfo1();
      // console.log(
      //   this.shopInfoModel1[0].orgName.split("-")[1] +
      //     "-" +
      //     this.shopInfoModel1[0].baseMatklName.split('-')[0]
      // );
      let p = this.shopInfoCheckDetailList.some((item) => {
        return (
          item.orgName.split("-")[1] + "-" + item.baseMatklName.split("-")[0] ==
          this.shopInfoModel1[0].orgName.split("-")[1] +
            "-" +
            this.shopInfoModel1[0].baseMatklName.split("-")[0]
        );
      });
      if (p&&!this.edit) {
             this.$message.warning("该所属办事处-物料组已维护！");
             return false;
           }
      this.shopInfoCheckDetailList = [
        ...new Set([...this.shopInfoCheckDetailList, ...this.shopInfoModel1]),
      ];
      if (this.edit) {
         this.edit=false
      }
    
      
      this.shopInfoModel1 = [
        {
          orgId: "", //办事处
          orgName: "", //办事处
          fgsName: "", //分公司
          fgsId: "", //分公司
          baseMatklId: "", //物料组
          baseMatklName: "", //物料组
          managerName: "", //仓库联系人姓名
          managerTel: "", //仓库联系人电话
          enterTime: "", //进驻时间
        },
      ];
       this.showAdder();
    },
    //物料组发生改变
    wuliaochange(e) {
      let supobj = this.wuliaozu[0].find(
        (i) => e == i.materialGroupBranchCompany2
      )
      this.shopInfoModel1[0].baseMatklName = supobj.materialGroupBranchCompany2;
      this.shopInfoModel1[0].baseMatklId = supobj.materialGroupBranchCompany;
      this.shopInfoModel1[0].fgsId=supobj.branchOfficeid
     
      if (this.sessionData[0].showSelect) {
        var data1 = this.shopInfoModel1[0].fgsId;
        getBscList(data1).then((res) => {
          this.banshichu = res.data.list;
        });
      }
    },

    //所属商家发生改变
    goGetMatklList() {
      this.shopInfoCheckDetailList = [];
      // getMatklListNew().then((res) => {
      //   this.baseMatklList = res.data.list;
      // });
      // debugger
      //得到物料组
      getMatklList(this.formData.ownedBusiness).then(res => {
          this.baseMatklList = res.data.list
      })
    },
    //物料组发生改变
    goGetBscList(feed) {
      let shopObj = this.shopInfoModel[0];
      let fgsObj = this.baseMatklList.find((i) => i.fullName == feed);
      // debugger
      // shopObj.baseMatklId = fgsObj.materialGroupId;
      // shopObj.baseMatklName = fgsObj.name;
      shopObj.baseMatklId = fgsObj.matklId;
      shopObj.baseMatklName = fgsObj.matklName;
      shopObj.fgsId = fgsObj.fwOrgId
      shopObj.fgsName = fgsObj.fwOrgName
      // debugger
      //得到办事处
      
      getBscList(shopObj.fgsId).then((res) => {
        // getBscList(fgsObj.branchCompanyId).then((res) => {
        this.bscList = res.data.list;
      });
    },
    //三级联动弹窗
    popupVisibleChange(isOpen) {
    
      // debugger
      if (this.filForm.length) {
        return;
      }
      //第一次打开 或者 清空后无数据 --》请求数据
      getRegin({ pCode: 0 }).then((res) => {
        this.filForm = res.data.list.map((item) => {
          return {
            value: item["code"],
            label: item["name"],
            isLeaf: false,
          };
        });
      });
    },
    loadData(selectedOptions, item) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      getRegin({ pCode: targetOption.value }).then((res) => {
        targetOption.loading = false;
        targetOption.children = res.data.list.map((item) => {
          return {
            value: item["code"],
            label: item["name"],
            isLeaf: item["desc"] == "child" ? true : false,
          };
        });

        this.filForm = [...this.filForm];
      });
    },
    onChange(value) {
      this.formData = { ...this.formData };
    },
    setDistributorWarehouse() {
      let filter = {
        custInfoId: "22756571", //所属商家
        fullName: "20220808-all-1", //商家仓库全称
        searchTerm: "288-1", //商家仓库简称
        provinceId: "16074", //省
        cityId: "14640", //市
        countyId: "73665", //区县
        townId: "73710", //乡镇/街道
        shAddress: "陕西省西安市新城区LL.FU(西北商贸中心)-1", //商家仓库地址
        postalCode: "274600", //邮编
        marketLevelId: "17250", //市场级别
      };
      filter.custInfoId = this.formData.ownedBusiness;
      filter.fullName = this.formData.storeFullName;
      filter.searchTerm = this.formData.storeShortName;
      // debugger
      filter.provinceId = this.addressArr[0] || "";
      filter.cityId = this.addressArr[1] || "";
      filter.countyId = this.addressArr[2] || "";
      filter.townId = this.addressArr[3] || "";

      filter.postalCode = this.formData.postCode;
      filter.marketLevelId = this.formData.marketLevel;
      // filter.shAddress =
      //   this.formData.ownedBusiness +
      //   Number(
      //     Math.random()
      //       .toString()
      //       .substr(3, length) + Date.now()
      //   ).toString(36);
      filter.shAddress = this.formData.mapAddress||''
      let paramsModel = {
        id: 0, //新增数据流程启动时为0
        custInfoId: "22756571", //所属商家
        fullName: "20220808-all-1", //商家仓库全称
        searchTerm: "288-1", //商家仓库简称
        marketLevelId: "17250", //市场级别
        provinceId: "16074", //省
        cityId: "14640", //市
        countyId: "73665", //区县
        townId: "73710", //乡镇/街道
        shAddress: "陕西省西安市新城区LL.FU(西北商贸中心)-1", //商家仓库地址
        postalCode: "274600", //邮编
        content: "123", //申请原因
        shopInfoCheckDetailList: [
          {
            orgId: "1049", //办事处
            orgName: "冰箱青岛-青二办办事处", //办事处
            fgsName: "冰箱青岛", //分公司
            fgsId: 1047, //分公司
            // "baseMatklId": "20160119018",//物料组
            // "baseMatklId": "20160119008",//物料组
            baseMatklId: "", //物料组
            baseMatklName: "海信冰箱", //物料组
            managerName: "ccc", //仓库联系人姓名
            managerTel: "22222222222", //仓库联系人电话
            enterTime: "2022-08-15", //进驻时间
          },
        ],
        deleteDetailList: [],
      };
      let params = { ...paramsModel, ...filter };

      params.shopInfoCheckDetailList = JSON.parse(
        JSON.stringify(this.shopInfoCheckDetailList)
      );
      // debugger
      params.shopInfoCheckDetailList.map((i) => {
        i.enterTime = Util.dateFmt(new Date(i.enterTime), "yyyy-MM-dd");
      });

      // debugger
      if (this.type == "edit") {
        let temp = JSON.parse(sessionStorage.getItem("selectedRow"));
        // debugger
        temp = temp[0];
        params.id = temp.id
      }
      return distributorWarehouse(params).then((res) => {
        this.submitLine.cooldown = false;
        if (res.data.code == 0) {
          this.$message.success("提交成功", 2);
          delStoreUser();
          delStoreUser1();
          setTimeout(() => {
            this.$router.push("/basicInfo?type=8");
          }, 2000);
        } else {
          // this.$message.info(res.data.msg, 2);
        }
      });
    },
    //一步建仓弹框
    showModal() {
      
      if(!this.formData.storeFullName){
        this.$message.warning("请填写仓库全称");
        return;
      }
      if(!this.formData.storeShortName){
        this.$message.warning("请填写仓库简称");
        return;
      }
      if(!this.formData.mapAddress){
        this.$message.warning("请填写仓库地址");
        return;
      }
      if(this.shopInfoCheckDetailList.length<1){
        this.$message.warning("请添加物料组合作关系");
        return;
      }
      
      
      this.visibledata = true;

    },
    //一步建仓提交
    hideModal2() {
      let jiancangdata = []
      if (this.shopInfoCheckDetailList.length > 0) {
        this.shopInfoCheckDetailList.forEach((i) => {
          jiancangdata.push({
            materialGroupBranchCompany: i.baseMatklId,
            orgId: i.orgId,
            managerTel: i.managerTel,
            managerName: i.managerName,
            enterTime: i.enterTime,
          });
        });
      }
      const hide = this.$message.loading("正在提交请稍等..", 0);
      setTimeout(hide, 2000);
      this.tijiaoable = true;

      let wareHouseInfoDto = {
        houseFullName: this.formData.storeFullName,
        houseSearchTerm: this.formData.storeShortName,
        houseAddress: this.formData.mapAddress,
        shopInfoDetailCkCheckDtoList: jiancangdata,
      };
      wareHouseInfoDto.shopInfoDetailCkCheckDtoList.map((i) => {
        i.enterTime = Util.dateFmt(new Date(i.enterTime), "yyyy-MM-dd");
      });
      let params = { ...this.sessionData[0], wareHouseInfoDto };
      this.visibledata = false;

      stepStartFlow(params).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("提交成功", 2);
          delStoreUser();
          delStoreUser1();
          this.tijiaoable = false;
          setTimeout(() => {
            this.$router.push("/basicInfo?type=3");
          }, 2000);
        } else {
          this.tijiaoable = false;
          this.$message.info(res.data.msg, 2);
        }
      });
    },

    fillShopInfo() {
      let shopObj = this.shopInfoModel[0];
      // let fgsObj = this.baseMatklList.find(i => i.fwOrgId == shopObj.fgsId)
      //根据所选办事处找到所有办事处的对应项
      let bscObj = this.bscList.find((i) => i.code == shopObj.orgId);
      // shopObj.baseMatklId = fgsObj.matklId
      // shopObj.baseMatklName = fgsObj.matklName
      // shopObj.fgsId = fgsObj.fwOrgId
      // shopObj.fgsName = fgsObj.fwOrgName
      shopObj.orgName = bscObj.name;
    },
    fillShopInfo1() {
      let shopObj = this.shopInfoModel1[0];
      // let fgsObj = this.baseMatklList.find(i => i.fwOrgId == shopObj.fgsId)
      let bscObj = this.banshichu.find((i) => i.code == shopObj.orgId);

      // shopObj.baseMatklId = fgsObj.matklId
      // shopObj.baseMatklName = fgsObj.matklName
      // shopObj.fgsId = fgsObj.fwOrgId
      // shopObj.fgsName = fgsObj.fwOrgName
      shopObj.orgName = bscObj.name;
    },
  },
};
